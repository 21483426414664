"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateMobileMeasurementExtremes = exports.mobileStreamSlice = exports.fetchMobileStreamById = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
exports.initialState = {
    data: {
        averageValue: 0,
        endTime: "",
        id: 0,
        maxLatitude: 0,
        maxLongitude: 0,
        measurements: [],
        minLatitude: 0,
        minLongitude: 0,
        notes: [],
        sensorName: "",
        sensorUnit: "",
        startLatitude: 0,
        startLongitude: 0,
        startTime: "",
        streamId: 0,
        title: "",
        username: "",
    },
    minMeasurementValue: 0,
    maxMeasurementValue: 0,
    averageMeasurementValue: 0,
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
    isLoading: false,
};
exports.fetchMobileStreamById = (0, toolkit_1.createAsyncThunk)("mobileStream/getData", (id, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchMobileStreamById(id));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchMobileStreamById",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchMobileStreamById(id),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
exports.mobileStreamSlice = (0, toolkit_1.createSlice)({
    name: "mobileStream",
    initialState: exports.initialState,
    reducers: {
        updateMobileMeasurementExtremes(state, action) {
            const { min, max } = action.payload;
            const measurementsInRange = state.data.measurements.filter((measurement) => {
                const time = measurement.time;
                return time >= min && time <= max;
            });
            const values = measurementsInRange.map((m) => m.value);
            const newMin = Math.min(...values);
            const newMax = Math.max(...values);
            const newAvg = values.reduce((sum, value) => sum + value, 0) / values.length;
            state.minMeasurementValue = newMin;
            state.maxMeasurementValue = newMax;
            state.averageMeasurementValue = newAvg;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exports.fetchMobileStreamById.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(exports.fetchMobileStreamById.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.data = action.payload;
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(exports.fetchMobileStreamById.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
            state.data = exports.initialState.data;
            state.isLoading = false;
        });
    },
});
exports.updateMobileMeasurementExtremes = exports.mobileStreamSlice.actions.updateMobileMeasurementExtremes;
exports.default = exports.mobileStreamSlice.reducer;
