"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagsInput = void 0;
const downshift_1 = require("downshift");
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const booleans_1 = require("../../const/booleans");
const hooks_1 = require("../../store/hooks");
const mapSlice_1 = require("../../store/mapSlice");
const sessionFiltersSlice_1 = require("../../store/sessionFiltersSlice");
const filters_1 = require("../../types/filters");
const mapParamsHandler_1 = require("../../utils/mapParamsHandler");
const Spinner_1 = require("../Loader/Spinner");
const FilterInfoPopup_1 = require("./FilterInfoPopup");
const S = __importStar(require("./SessionFilters.style"));
const TagsInput = () => {
    const [items, setItems] = (0, react_1.useState)([""]);
    const [inputValue, setInputValue] = (0, react_1.useState)("");
    const [selectedItem, setSelectedItem] = (0, react_1.useState)("");
    const dispatch = (0, hooks_1.useAppDispatch)();
    const { t } = (0, react_i18next_1.useTranslation)();
    const { boundWest, boundEast, boundNorth, boundSouth, sensorName, sessionType, setFilter, tags, timeFrom, timeTo, unitSymbol, usernames, isIndoor, isActive, } = (0, mapParamsHandler_1.useMapParams)();
    const tagsToSelect = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectTags);
    const selectedSessionType = sessionType || filters_1.SessionTypes.FIXED;
    const isTagsInputFetching = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectIsTagsInputFetching);
    const preparedUnitSymbol = unitSymbol.replace(/"/g, "");
    const getQueryParams = (tags) => {
        return {
            tags: tags,
            west: boundWest.toString(),
            east: boundEast.toString(),
            south: boundSouth.toString(),
            north: boundNorth.toString(),
            timeFrom: timeFrom,
            timeTo: timeTo,
            usernames: usernames,
            sensorName: sensorName,
            unitSymbol: preparedUnitSymbol,
            sessionType: selectedSessionType,
            isIndoor: isIndoor === booleans_1.TRUE,
            isActive: isActive,
        };
    };
    const { isOpen, getMenuProps, getInputProps, getItemProps, reset } = (0, downshift_1.useCombobox)({
        items: tagsToSelect,
        inputValue,
        selectedItem,
        onInputValueChange: ({ inputValue }) => {
            const queryParams = getQueryParams(inputValue);
            dispatch((0, sessionFiltersSlice_1.fetchTags)(queryParams));
            setInputValue(inputValue);
        },
        onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
            if (newSelectedItem !== null &&
                !(decodedTagsArray === null || decodedTagsArray === void 0 ? void 0 : decodedTagsArray.includes(newSelectedItem))) {
                const decodedTags = tags && decodeURIComponent(tags);
                const selectedTags = decodedTags + ", " + newSelectedItem;
                const urlEncodedString = encodeURIComponent(selectedTags);
                setFilter(mapParamsHandler_1.UrlParamsTypes.tags, urlEncodedString.toString());
                reset();
                setSelectedItem("");
            }
        },
    });
    const handleOnInputClick = () => {
        const queryParams = getQueryParams(inputValue);
        if (tagsToSelect.length === 0) {
            dispatch((0, sessionFiltersSlice_1.fetchTags)(queryParams));
        }
    };
    const decodedTagsArray = tags &&
        decodeURIComponent(tags)
            .split(", ")
            .filter((el) => el !== "");
    const displaySearchResults = isOpen && items.length > 0;
    const handleOnClose = (itemToRemove) => {
        const tagsUpdated = decodedTagsArray && decodedTagsArray.filter((el) => el !== itemToRemove);
        const decodedTagsString = tagsUpdated ? tagsUpdated.join(", ") : "";
        setFilter(mapParamsHandler_1.UrlParamsTypes.tags, decodedTagsString.toString());
    };
    (0, react_1.useEffect)(() => {
        setItems(tagsToSelect);
    }, [tagsToSelect]);
    (0, react_1.useEffect)(() => {
        setTimeout(() => {
            dispatch((0, mapSlice_1.setFetchingData)(true));
        }, 200);
    }, [tags]);
    return (react_1.default.createElement(S.Wrapper, null,
        react_1.default.createElement(S.SingleFilterWrapper, null,
            react_1.default.createElement(S.InputWrapper, null,
                react_1.default.createElement(S.Input, Object.assign({ placeholder: t("filters.tags") }, getInputProps({
                    value: inputValue,
                    onClick: handleOnInputClick,
                }))),
                isTagsInputFetching && react_1.default.createElement(Spinner_1.Spinner, null)),
            react_1.default.createElement(FilterInfoPopup_1.FilterInfoPopup, { filterTranslationLabel: "filters.tagNamesInfo" })),
        decodedTagsArray && decodedTagsArray.length > 0 && (react_1.default.createElement(S.SelectedItemsWrapper, null, decodedTagsArray.map((item, index) => (react_1.default.createElement(S.SelectedItemTile, { key: index },
            react_1.default.createElement(S.SelectedItem, null, item),
            react_1.default.createElement(S.CloseSelectedItemButton, { onClick: () => handleOnClose(item) })))))),
        react_1.default.createElement(S.SuggestionList, Object.assign({ "$displaySearchResults": displaySearchResults }, getMenuProps()), items.map((item, index) => (react_1.default.createElement(S.Suggestion, Object.assign({ key: index }, getItemProps({ item, index })), item))))));
};
exports.TagsInput = TagsInput;
