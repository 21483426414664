"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileSessionFilters = void 0;
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const booleans_1 = require("../../const/booleans");
const fixedSessionsSelectors_1 = require("../../store/fixedSessionsSelectors");
const hooks_1 = require("../../store/hooks");
const indoorSessionsSelectors_1 = require("../../store/indoorSessionsSelectors");
const mobileSessionsSelectors_1 = require("../../store/mobileSessionsSelectors");
const sensorsSlice_1 = require("../../store/sensorsSlice");
const sessionFiltersSlice_1 = require("../../store/sessionFiltersSlice");
const filters_1 = require("../../types/filters");
const sensors_1 = require("../../types/sensors");
const mapParamsHandler_1 = require("../../utils/mapParamsHandler");
const Legend_style_1 = require("../Map/Legend/Legend.style");
const CrowdmapToggle_1 = require("./CrowdmapToggle");
const CustomParameterFilter_1 = require("./CustomParameterFilter");
const CustomSensorFilter_1 = require("./CustomSensorFilter");
const DormantToggle_1 = require("./DormantToggle");
const IndoorOutdoorSwitch_1 = require("./IndoorOutdoorSwitch");
const ParameterFilter_1 = require("./ParameterFilter");
const ProfileNamesInput_1 = require("./ProfileNamesInput");
const SensorFilter_1 = require("./SensorFilter");
const S = __importStar(require("./SessionFilters.style"));
const SessionTypeToggle_1 = require("./SessionTypeToggle");
const TagsInput_1 = require("./TagsInput");
const YearPicker_1 = require("./YearPicker");
const MobileSessionFilters = ({ onClose, fetchableSessionsCount, }) => {
    const indoorSessionsState = (0, hooks_1.useAppSelector)(indoorSessionsSelectors_1.selectIndoorSessionsState);
    const dormantFixedSessionsState = (0, hooks_1.useAppSelector)(fixedSessionsSelectors_1.selectDormantFixedSessionsState);
    const activeFixedSessionsState = (0, hooks_1.useAppSelector)(fixedSessionsSelectors_1.selectActiveFixedSessionsState);
    const fixedSessionsType = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectFixedSessionsType);
    const mobileSessionsState = (0, hooks_1.useAppSelector)(mobileSessionsSelectors_1.selectMobileSessionsState);
    const basicParametersModalOpen = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectBasicParametersModalOpen);
    const customParametersModalOpen = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectCustomParametersModalOpen);
    const [isBasicParametersModalOpen, setIsBasicParametersModalOpen] = (0, react_1.useState)(basicParametersModalOpen);
    const [isCustomParametersModalOpen, setIsCustomParametersModalOpen] = (0, react_1.useState)(customParametersModalOpen);
    const basicSensorsModalOpen = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectBasicSensorsModalOpen);
    const customSensorsModalOpen = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectCustomSensorsModalOpen);
    const [isBasicSensorsModalOpen, setIsBasicSensorsModalOpen] = (0, react_1.useState)(basicParametersModalOpen);
    const [isCustomSensorsModalOpen, setIsCustomSensorsModalOpen] = (0, react_1.useState)(customParametersModalOpen);
    const { sessionType, measurementType, isIndoor, sensorName } = (0, mapParamsHandler_1.useMapParams)();
    const { t } = (0, react_i18next_1.useTranslation)();
    const parameters = (0, hooks_1.useAppSelector)(sensorsSlice_1.selectParameters);
    const customParameters = (0, ParameterFilter_1.filterCustomParameters)(parameters, sessionType);
    const sensors = (0, hooks_1.useAppSelector)(sensorsSlice_1.selectSensors);
    const customSensors = (0, SensorFilter_1.filterCustomSensors)(sensors, measurementType, sessionType);
    const isIndoorParameterInUrl = isIndoor === booleans_1.TRUE;
    const airBeamSensorNameSelected = sensorName.startsWith(sensors_1.SensorPrefix.AIR);
    const govermentSensorNameSelected = sensorName.startsWith(sensors_1.SensorPrefix.GOVERNMENT);
    const isFixedSessionTypeSelected = sessionType === filters_1.SessionTypes.FIXED;
    const isDormant = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectIsDormantSessionsType);
    const sessionsCount = (0, react_1.useMemo)(() => {
        switch (sessionType) {
            case filters_1.SessionTypes.FIXED:
                return isIndoorParameterInUrl
                    ? isDormant
                        ? indoorSessionsState.dormantIndoorSessions.length
                        : indoorSessionsState.activeIndoorSessions.length
                    : isDormant
                        ? dormantFixedSessionsState.length
                        : activeFixedSessionsState.length;
            case filters_1.SessionTypes.MOBILE:
                return mobileSessionsState.sessions.length;
        }
    }, [
        mobileSessionsState,
        sessionType,
        indoorSessionsState,
        dormantFixedSessionsState,
        activeFixedSessionsState,
        mobileSessionsState,
        sessionType,
    ]);
    (0, react_1.useEffect)(() => {
        setIsBasicParametersModalOpen(basicParametersModalOpen);
        setIsCustomParametersModalOpen(customParametersModalOpen);
        setIsBasicSensorsModalOpen(basicSensorsModalOpen);
        setIsCustomSensorsModalOpen(customSensorsModalOpen);
    }, [
        basicParametersModalOpen,
        customParametersModalOpen,
        basicSensorsModalOpen,
        customSensorsModalOpen,
    ]);
    return (react_1.default.createElement(S.MobileSessionFilters, null, isBasicParametersModalOpen ? (react_1.default.createElement(ParameterFilter_1.MobileDeviceParameterFilter, { customParameters: customParameters, sessionsCount: sessionsCount, onClose: onClose, fetchableSessionsCount: fetchableSessionsCount })) : isCustomParametersModalOpen ? (react_1.default.createElement(CustomParameterFilter_1.CustomParameterFilter, { customParameters: customParameters, sessionsCount: sessionsCount, onClose: onClose, fetchableSessionsCount: fetchableSessionsCount })) : isBasicSensorsModalOpen ? (react_1.default.createElement(SensorFilter_1.MobileDeviceSensorFilter, { customSensors: customSensors, sessionsCount: sessionsCount, onClose: onClose, fetchableSessionsCount: fetchableSessionsCount })) : isCustomSensorsModalOpen ? (react_1.default.createElement(CustomSensorFilter_1.CustomSensorFilter, { customSensors: customSensors, sessionsCount: sessionsCount, onClose: onClose, fetchableSessionsCount: fetchableSessionsCount })) : (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(S.ModalContent, null,
            react_1.default.createElement(S.Header, null,
                react_1.default.createElement(Legend_style_1.CloseButton, { onClick: onClose }),
                react_1.default.createElement(S.HeaderTitle, null, t("filters.editFilters"))),
            react_1.default.createElement(SessionTypeToggle_1.SessionTypeToggle, null),
            react_1.default.createElement(ParameterFilter_1.ParameterFilter, { isBasicOpen: isBasicParametersModalOpen }),
            react_1.default.createElement(SensorFilter_1.SensorFilter, { isBasicOpen: isBasicSensorsModalOpen }),
            !govermentSensorNameSelected && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ProfileNamesInput_1.ProfileNamesInput, null),
                react_1.default.createElement(TagsInput_1.TagsInput, null))),
            isFixedSessionTypeSelected && airBeamSensorNameSelected && (react_1.default.createElement(IndoorOutdoorSwitch_1.IndoorOutdoorSwitch, null)),
            isFixedSessionTypeSelected && react_1.default.createElement(DormantToggle_1.DormantToggle, null),
            !isFixedSessionTypeSelected && react_1.default.createElement(YearPicker_1.YearPicker, null),
            !isFixedSessionTypeSelected && react_1.default.createElement(CrowdmapToggle_1.CrowdMapToggle, null)),
        react_1.default.createElement(S.ShowSessionsButton, { onClick: onClose }, isFixedSessionTypeSelected ? (react_1.default.createElement(react_1.default.Fragment, null,
            t("filters.showSessions"),
            " (",
            sessionsCount,
            ")")) : (react_1.default.createElement(react_1.default.Fragment, null,
            t("filters.showSessions"),
            " ",
            t("map.results", {
                results: sessionsCount,
                fetchableSessionsCount,
            }))))))));
};
exports.MobileSessionFilters = MobileSessionFilters;
