"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const media_1 = require("./media");
const useScreenSizeDetection = (maxWidth = media_1.screenSizes.desktop) => {
    const [isMobile, setIsMobile] = (0, react_1.useState)(window.innerWidth <= maxWidth);
    (0, react_1.useEffect)(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < maxWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [maxWidth]);
    return isMobile;
};
exports.default = useScreenSizeDetection;
