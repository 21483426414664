"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIN_ZOOM = exports.DEFAULT_ZOOM = exports.DEFAULT_MAP_CENTER = exports.DEFAULT_MAP_BOUNDS = void 0;
const DEFAULT_MAP_CENTER = {
    lat: 37.08877211846209,
    lng: -95.72238290534534,
};
exports.DEFAULT_MAP_CENTER = DEFAULT_MAP_CENTER;
const DEFAULT_MAP_BOUNDS = {
    north: 49.3457868,
    south: 24.396308,
    west: -125.00001,
    east: -66.93457,
};
exports.DEFAULT_MAP_BOUNDS = DEFAULT_MAP_BOUNDS;
const DEFAULT_ZOOM = 5;
exports.DEFAULT_ZOOM = DEFAULT_ZOOM;
const MIN_ZOOM = 3;
exports.MIN_ZOOM = MIN_ZOOM;
