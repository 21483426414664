"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopyLinkComponent = void 0;
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const useAutoDismissAlert_1 = require("../../utils/useAutoDismissAlert");
const CopyLinkModal_1 = require("../Modals/CopyLinkModal");
const ConfirmationMessage_1 = require("../Modals/atoms/ConfirmationMessage");
const S = __importStar(require("./Popups.style"));
const CopyLinkPopup = (props) => {
    return react_1.default.createElement(S.SmallPopup, Object.assign({}, props));
};
const CopyLinkComponent = ({ button, isIconOnly, showBelowButton, onOpen, onClose, }) => {
    const [showConfirmation, setShowConfirmation] = (0, react_1.useState)(false);
    const [buttonPosition, setButtonPosition] = (0, react_1.useState)({ top: 0, left: 0 });
    const buttonRef = (0, react_1.useRef)(null);
    const { t } = (0, react_i18next_1.useTranslation)();
    const handleCopySubmit = (formData, close) => {
        close();
        setShowConfirmation(true);
        onClose && onClose();
    };
    const handleCopyError = (error) => {
        console.error("Error copying link: ", error.message);
        alert(t("alert.linkShortenedFailed"));
    };
    const rect = buttonRef.current && buttonRef.current.getBoundingClientRect();
    const updateButtonPosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setButtonPosition({ top: rect.top, left: rect.left });
        }
    };
    (0, react_1.useEffect)(() => {
        updateButtonPosition();
        window.addEventListener("resize", updateButtonPosition);
        return () => {
            window.removeEventListener("resize", updateButtonPosition);
        };
    }, [buttonRef.current]);
    (0, useAutoDismissAlert_1.useAutoDismissAlert)(showConfirmation, setShowConfirmation);
    (0, react_1.useEffect)(() => {
        updateButtonPosition();
    }, [rect === null || rect === void 0 ? void 0 : rect.top]);
    return (react_1.default.createElement(S.WrapperButton, { ref: buttonRef },
        react_1.default.createElement(CopyLinkPopup, { trigger: button, position: `${showBelowButton ? "bottom" : "top"} center`, nested: true, arrow: true, closeOnDocumentClick: true, onOpen: onOpen, onClose: onClose, contentStyle: showBelowButton
                ? {
                    top: buttonPosition.top + 35,
                    left: buttonPosition.left - 40,
                    position: "absolute",
                }
                : {} }, (close) => (react_1.default.createElement(CopyLinkModal_1.CopyLinkModal, { onSubmit: (formData) => handleCopySubmit(formData, close), onError: handleCopyError }))),
        showConfirmation && (react_1.default.createElement(S.ConfirmationPopup, { open: showConfirmation, closeOnDocumentClick: false, arrow: false, contentStyle: {
                top: `${showBelowButton
                    ? buttonPosition.top + 35
                    : buttonPosition.top - 45}px`,
                left: `${isIconOnly
                    ? buttonPosition.left - 12
                    : showBelowButton
                        ? buttonPosition.left + 20
                        : buttonPosition.left + 38}px`,
                position: "absolute",
            } },
            react_1.default.createElement(ConfirmationMessage_1.ConfirmationMessage, { message: t("copyLinkModal.confirmationMessage") })))));
};
exports.CopyLinkComponent = CopyLinkComponent;
